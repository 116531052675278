import * as React from 'react';
import {
    format,
    Tooltip
} from '@msdyn365-commerce-modules/utilities';
import { ICartState } from '@msdyn365-commerce/global-state';
import { humanizeUnitOfMeasure } from '../../../components/unit-of-measure';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

export interface ICartCountProps {
    locale: string;
    product: SimpleProduct;
    productId: number;
    cart: ICartState;
    inCartTooltipText?: string;
}

const CartCount = (props: ICartCountProps) => {
    const { cart, productId, inCartTooltipText } = props;
    const [showCartCountTooltip, setShowCartCountTooltip] = React.useState<boolean>(false);

    // const cartQuantity = cart?.cart.CartLines?.find(x => x.ProductId === productId)?.Quantity;
    const cartQuantity = cart?.cart.CartLines?.filter(x => x.ProductId === productId).reduce((acc, val) => acc + (val.Quantity || 0), 0);

    if (!cartQuantity) {
        return null;
    }

    const cartCountId = `productResults-item-cart-count-${productId}`;
    const tooltipString = inCartTooltipText || '{0} {1} i handlekurven';
    const tooltipText = format(tooltipString, cartQuantity, humanizeUnitOfMeasure(props.locale, props.product.DefaultUnitOfMeasure, props.product));

    return (
        <span
            id={cartCountId}
            className={`msc-autoSuggest__productResults-item-cart-count`}
            onMouseOver={() => setShowCartCountTooltip(true)}
            onMouseOut={() => setShowCartCountTooltip(false)}
            onTouchStart={() => setShowCartCountTooltip(true)}
        >
            {cartQuantity}
            <Tooltip
                isOpen={showCartCountTooltip}
                target={`#${cartCountId}`}
                className='msc-cart-count-tooltip'
            >
                {tooltipText}
            </Tooltip>
        </span>
    );
};

export default CartCount;
